import { Fragment } from "react";

import Head from "next/head";
import { Footer } from "./../partials/Footer";

export function BaseLayout({ children }) {
  return (
    <Fragment>
      <Head>
        <title>Wynajmij z Gleevery</title>
        <meta
          name="description"
          content="Gleevery to innowacyjne rozwiązanie, które stwarza dla Ciebie unikalną sytuację win-win-win. Ty wynajmujesz ulubione produkty. Wskazany sprzęt otrzymuje dłuższe życie. Środowisko zyskuje na zoptymalizowanym procesie dystrybucji technologii."
        />
      </Head>
      <div className="flex-grow-1 mb-2 mb-md-8">
        <section>{children}</section>
      </div>
      <Footer />
    </Fragment>
  );
}
